import './assets/sass/main.scss';

var link = document.querySelector("link[rel~='icon']");
if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
    link.href = '/favicon.svg';
}
const keys = ["smartlink_defaults","user","site_upgrade_notification","dark_mode"];
const envs = ['stage','prod'];
const PREFIX = __LOCAL_STORAGE_PREFIX__;
envs.forEach(env => {
    keys.forEach(key => {
        let item = window.localStorage.getItem(PREFIX+env+key);
        if (item){
            window.localStorage.removeItem(PREFIX+env+key);
            if (!window.localStorage.getItem(PREFIX+key)){
                window.localStorage.setItem(PREFIX+key,item);
            }
        }
    });
});

(function() {
    Promise.all([
        System.import('single-spa'),
        System.import('vue'),
    ]).then(function (modules) {
        var singleSpa = modules[0];
        console.debug("Vue loaded: "+window.Vue.version);
        System.set(System.resolve('vue'), window.Vue)
        let content = document.getElementById('content');
        let title = document.getElementById('content-title')
        var initApp = function(){
            singleSpa.registerApplication(
                'site',
                () =>  {
                    return System.import('@sugarcx/support');
                },
                location => true,
                {
                    el: "#app",
                    content: (content?content.innerHTML:""),
                    title: (title?title.innerHTML:""),
                    config: quasarConfig || {}
                }
            );
            singleSpa.start({
                urlRerouteOnly: true
            });
        }

        Promise.all([
            System.import('vue-router'),
            System.import('vuex'),
            System.import('quasar')
        ]).then(function(vueModules){
            if (!window.Quasar){
                window.Quasar = vueModules[2];
            }
            console.debug("Vuex loaded: "+window.Vuex.version);
            console.debug("VueRouter loaded: ",!!window.VueRouter);
            console.debug("Quasar loaded: "+window.Quasar.version);
            System.set(System.resolve('vue-router'), window.VueRouter)
            System.set(System.resolve('vuex'), window.Vuex)
            System.set(System.resolve('quasar'), window.Quasar)
            initApp();
        })
    });
})();